export default uiMfp = ($timeout) ->
  restrict: 'A',
  scope:
    title: '@'
  link: (s, el, attr) ->
    $timeout(() ->
      $(el).magnificPopup({
        type: 'image',
        delegate: 'a',
        tLoading: 'Загрузка изображения #%curr%...',
        closeOnContentClick: false,
        closeBtnInside: true,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1]
        },
        image: {
          verticalFit: true,
          titleSrc: (item) ->
            return item.el.attr('title') + ' &middot; <a class="image-source-link" href="' + item.el.data('mfpSrc') + '" target="_blank">Оригинал</a>'
        },
      })
    , 100)

uiMfp.$inject = ['$timeout']