"use strict";
const isBotBrowser = /bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex/i.test(navigator.userAgent);

window.InitApp = function () {
    // Foundation JavaScript
    // Documentation can be found at: http://foundation.zurb.com/docs
    $(document).foundation({
        tooltip: {
            selector: '.tooltip.filter__item',
            additional_inheritable_classes: [],
            tooltip_class: '.tooltip',
            touch_close_text: '',
            disable_for_touch: true,
            tip_template: function (selector, content) {
                return '<span data-selector="' + selector + '" class="'
                    + Foundation.libs.tooltip.settings.tooltip_class.substring(1)
                    + '">' + content + '<span class="nub"></span></span>';
            }
        }
    });
    $(document).foundation('tooltip', 'reflow');

    var productGallerySlider = $('.mfp-place-gallery .bxslider').bxSlider({
        mode: 'horizontal',
        captions: true,
        slideWidth: 600
    });

    $('a.gallery-sublink').each(function (index, elem) {
        $(elem).on('click', function () {
            productGallerySlider.goToSlide(index)
        });
        if ($(elem).data('href')) {

        }
    });

    var $body = angular.element(document.body);
    var $rootScope = $body.injector().get('$rootScope');

    $('a').closest('div.b-membership').click(function (event) {
        const $id = $(this).attr('data-bn');
        const $target = $(this).data('target');
        const $url = $(this).data('url');
        const $a = $(this).find('a');

        $.get("/ban/js_api/log_click/" + $id + "/");

        if ($a.length) {
            const $a1 = $($a[0]);
            if ($a1.attr('href') == '#' || $a1.attr('href') == '') {
                if ($url && $target) {
                    window.open($url, $target);
                    return false;
                }
            }
        }
    });

    $('div.f-product__text').dotdotdot({
        watch: true,
        after: 'a.read-more',
        callback: function (t) {
            if (t) {
                var $link = $('.read-more', $(this));
                $link.show();
                $link.click(function () {
                    $link.closest('.f-product__text').triggerHandler("originalContent", function (content) {
                        $link.remove();
                        $(this).empty().append(content).addClass('open')
                    })
                })
            }
        }
    });

    var content = $("#wrapper").triggerHandler("originalContent");
    $("#foo").append(content);

    var $form = $('form.cart-add');
    var time = null;
    var block = $("#sub-modal");
    var block_tpl = $("#cart-add-notify");

    $form.ajaxForm({
        beforeSubmit: function (arr, $form, options) {
            $form.find('button[type=submit]').hide();
        },
        success: function (resp, statusText, xhr, $form) {
            $form.find('button[type=submit]').show();
            if (statusText === 'success') {
                $rootScope.$apply(function () {
                    $rootScope.$broadcast('$total', resp.total);
                    $rootScope.$broadcast('$cart_item_count', resp.cart_item_count);
                });
                if (block.hasClass('show')) {
                    closeSubModal();
                    openSubModal(resp);
                } else {
                    openSubModal(resp);
                }
            }
        },
        error: function (resp, statusText, xhr, $form) {
            $form.find('button[type=submit]').show();
            const data = resp.responseJSON;

            $.each((data.non_field_errors || []), function (i, v) {
                $.magnificPopup.open({
                    items: {
                        type: 'inline',
                        preloader: false,
                        modal: true,
                        src: _.template($('#mgp-' + v.code).html())({
                            data: v
                        })
                    }, callbacks: {
                        open: function () {
                            var $add_all = $('.mgp-add-all');
                            if ($add_all)
                                $add_all.click(function (e) {
                                    e.preventDefault();
                                    $('input[name=quantity]', $form).val(v.rest_count_minus_added);
                                    $.magnificPopup.instance.close();
                                    $form.submit();
                                    return false
                                });
                            $('.mgp-dismiss').click(function (e) {
                                e.preventDefault();
                                $.magnificPopup.instance.close();
                                return false
                            })
                        }
                    }
                });
                return false
            });

            if (data.detail)
                $.magnificPopup.open({
                    items: {
                        type: 'inline',
                        preloader: false,
                        modal: true,
                        src: _.template($('#mgp-msg').html())({
                            msg: data.detail
                        })
                    }, callbacks: {
                        open: function () {
                            $('.mgp-dismiss').click(function (e) {
                                e.preventDefault();
                                $.magnificPopup.instance.close();
                                return false
                            })
                        }
                    }
                });
            return
        }
    });

    // $('.popup-youtube').magnificPopup({
    //     disableOn: 700,
    //     type: 'iframe',
    //     mainClass: 'mfp-fade',
    //     removalDelay: 160,
    //     preloader: false,
    //     fixedContentPos: false
    // });

    $('.mfp-place-gallery').magnificPopup({
        key: 'product',
        disableOn: 700,
        emovalDelay: 160,
        reloader: false,
        delegate: 'a.gallery',
        // type: 'image',
        closeOnContentClick: false,
        closeBtnInside: true,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        image: {
            verticalFit: true,
            titleSrc: function (item) {
                return item.el.attr('title') + ' &middot; <a class="image-source-link" href="' + item.el.attr('data-mfp-src') + '" target="_blank">Оригинал</a>';
            }
        },
        cursor: 'mfp-zoom-out-cur',
        gallery: {
            enabled: true
        },
        zoom: {
            enabled: false,
            duration: 300, // don't foget to change the duration also in CSS
            opener: function (openerElement) {
                return openerElement.is('img') ? openerElement : openerElement.find('img');
            }
        },
        callbacks: {
            close: function () {
                // $(document).foundation('orbit', 'reflow')
            },
            markupParse: function (template, values, item) {
                //  Triggers each time when content of popup changes
                // console.log('Parsing:', template, values, item);
                if (item.el.hasClass('vertical-video'))
                    $('iframe', template).addClass('vertical')
            },
        }

    });

    $('.mfp-place').magnificPopup({
        delegate: 'a.gallery',
        type: 'image',
        closeOnContentClick: false,
        closeBtnInside: true,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        image: {
            verticalFit: true,
            titleSrc: function (item) {
                return item.el.attr('title') + ' &middot; <a class="image-source-link" href="' + item.el.attr('data-mfp-src') + '" target="_blank">Оригинал</a>';
            }
        },
        gallery: {
            enabled: false
        },
        zoom: {
            enabled: false,
            duration: 300, // don't foget to change the duration also in CSS
            opener: function (element) {
                return element.find('img');
            }
        },
    });

    //изменение отображения вида
    // if ($('.filter-view').length) {
    //
    // }

    var viewType = localStorage['view'] || $('.filter__item.active').data('view');
    //если есть запись в памяти то присваиваем класс и отображаем + менем отображение кнопки
    if (viewType) {
        var elem = $(document.querySelectorAll('[data-view=' + viewType + ']')),
            elems = $('.filter__item');
        // elems.attr('class', 'filter__item tooltip');
        elems.removeClass('active');
        elem.addClass('active');
        setView(viewType);
    }

    $('.filter__item-svg').click(function (e) {
        // по клику меняем отображение и сохраняем в локал
        var elems = $('.filter__item');

        if ($(e.target).is('svg')) {
            var elem = $(e.target).parent();
        }
        if ($(e.target).is('path')) {
            var elem = $(e.target).parent().parent();
        }
        if (elem.hasClass('filter__item') && !elem.hasClass('active')) {
            elems.attr('class', 'filter__item tooltip');
            elem.addClass('active');
            var viewType = elem.data('view');
            localStorage['view'] = viewType;
            setView(viewType);

        }
        // смена оторажения на странице акций
        // $('.subject__list').removeClass('subject__list_columns');
        // if(elem.data('view') == 'list-greed' && elem.hasClass('active')) {
        //     $('.subject__list').addClass('subject__list_columns');
        // }
    });

    function setView(viewType) {
        //функция отображения в зависимости от вида
        var mainList = $('.goods__list .f-product__item > div'),
            mainListItem = $('.goods__list .f-product__item > div > div'),
            parent = $('.f-product__list.goods__list');


        parent.attr('class', '');
        parent.attr('class', 'f-product__list goods__list ' + viewType);

        switch (viewType) {
            case 'list-greed' : {
                mainList.attr('class', 'row columns');
                mainListItem.attr('class', 'small-12 medium-12 columns');
                $('.subject__list').addClass('subject__list_columns');
                break;
            }
            case 'list-columns' : {
                mainList.attr('class', 'row rows');
                mainList.each(function () {
                    mainListItem = $(this).children();

                    if (mainListItem.length === 3) {
                        $(mainListItem[0]).attr('class', 'small-12 medium-3 columns');
                        $(mainListItem[1]).attr('class', 'small-12 medium-6 columns');
                        $(mainListItem[2]).attr('class', 'small-12 medium-3 columns');
                    }
                    if (mainListItem.length === 2) {
                        $(mainListItem[0]).attr('class', 'small-12 medium-3 columns');
                        $(mainListItem[1]).attr('class', 'small-12 medium-9 columns');
                    }
                });
                $('.subject__list').removeClass('subject__list_columns');
                break;
            }
        }
    }


    // корзина
    if ($('.cart-sidebar').length) {
        var headingHeight = $('.heading').outerHeight();
        var cart = $('.cart-sidebar');
        var cartTitle = $('.cart__title');
        var cartList = $('.cart-list');
        var cartListInitialWith = cartList.outerWidth();
        //var coordsTop = cart.offset().top - headingHeight;
        //var breadTop = $('.breadcrumbs').offset().top;
        //var cartHeight = cart.outerHeight() + headingHeight;
        //var coordeLeft = cartList.offset().left + cartList.outerWidth() + 10;
        var fixValue = 0;
        var result = 0;


        //-------------------------------------


        let breadTop = $('.breadcrumbs').offset().top;

        if ($(window).width() < 768) {
            $('.header').hide();
            headingHeight = $('.heading').outerHeight();
        } else {
            $('.header').show();
            headingHeight = $('.heading').outerHeight();
        }

        if ($(window).width() > 965) {
            cart.css({
                'left': cartList.offset().left + cartList.outerWidth() + 'px'
            })
        } else {
            cart.css({
                'left': 0
            })
        }

        if ($(window).width() > 965 && $(window).width() < 1440) {
            var cartWidth;
            var ratio;
            cartWidth = (cartTitle.outerWidth() * 100) / $(window).width();
            ratio = 34 - (100 - cartWidth);
            cartList.css({
                //'width': cartListInitialWith - ((cartListInitialWith * (100 - cartWidth)) / 100) + 'px'
                'width': 970 - ((970 / 100) * ratio) + 'px'
                //'width': 1200 - ((1200 / 100) * ratio) + 'px'
            });
            if (cart.hasClass('fixed-top')) {
                cart.css({
                    'left': cartList.offset().left + (970 - ((970 / 100) * ratio)) + 'px'
                    //'left': cartList.offset().left + (1200 - ((1200 / 100) * ratio)) + 'px'
                })

            } else {
                cart.css({
                    'left': 970 - ((970 / 100) * ratio) + 'px'
                    //'left': 1200 - ((1200 / 100) * ratio) + 'px'
                })
            }

        } else {
            cartList.css({
                'width': '100%'
            });
            if ($(window).width() < 965) {
                cart.css({
                    'left': '0'
                })
            }
            if ($(window).width() >= 1440) {
                cart.css({
                    'left': cartList.outerWidth() + 'px'
                })
            }
        }

        //-------------------------------------
        $(window).resize(function () {
            breadTop = $('.breadcrumbs').offset().top;
            if ($(window).width() < 768) {
                $('.header').hide();
                headingHeight = $('.heading').outerHeight();
            } else {
                $('.header').show();
                headingHeight = $('.heading').outerHeight();
            }
            if ($(window).width() > 965) {

                cart.css({
                    'left': cartList.offset().left + cartList.outerWidth() + 'px'
                })
            } else {
                cart.css({
                    'left': 0
                })
            }


            if ($(window).width() > 965 && $(window).width() < 1440) {
                var cartWidth;
                var ratio;
                cartWidth = (cartTitle.outerWidth() * 100) / $(window).width();
                ratio = 34 - (100 - cartWidth);
                cartList.css({
                    //'width': cartListInitialWith - ((cartListInitialWith * (100 - cartWidth)) / 100) + 'px'
                    'width': 970 - ((970 / 100) * ratio) + 'px'
                    //'width': 1200 - ((1200 / 100) * ratio) + 'px'
                });
                if (cart.hasClass('fixed-top')) {
                    cart.css({
                        'left': cartList.offset().left + (970 - ((970 / 100) * ratio)) + 'px'
                        //'left': cartList.offset().left + (1200 - ((1200 / 100) * ratio)) + 'px'
                    })

                } else {
                    cart.css({
                        'left': 970 - ((970 / 100) * ratio) + 'px'
                        //'left': 1200 - ((1200 / 100) * ratio) + 'px'
                    })
                }

            } else {
                cartList.css({
                    'width': '100%'
                });
                if ($(window).width() < 965) {
                    cart.css({
                        'left': '0'
                    })
                }
                if ($(window).width() >= 1440) {
                    cart.css({
                        'left': cartList.outerWidth() + 'px'
                    })
                }
            }
        });

        $(document).on('scroll', function (e) {
            if (cart.hasClass('fixed-top')) {
                if ($(window).scrollTop() < fixValue) {
                    cart.removeClass('fixed-top');
                    cartTitle.css({
                        'margin-top': '0px'
                    });
                    if ($(window).width() > 965) {
                        cart.css({
                            'left': cartList.outerWidth() + 'px'
                        })

                    } else {
                        cart.css({
                            'left': '0'
                        })

                    }
                }
            } else {
                result = cart.offset().top - $(window).scrollTop();
                if (result < headingHeight) {
                    cart.addClass('fixed-top');
                    fixValue = $(window).scrollTop();
                    if ($(window).width() < 965) {
                        cartTitle.css({
                            'margin-top': cart.outerHeight() + 'px'
                        });
                        cart.css({
                            'left': '0'
                        })
                    } else {
                        cart.css({
                            'left': cartList.offset().left + cartList.outerWidth() + 'px'
                        })
                    }
                }
            }
        });
    }

    // Всплывающее окно добавления товара
    function closeSubModal() {
        window.clearTimeout(time);
        if (block.hasClass('show')) {
            block.removeClass('show');
            block.stop(true, true)
                .animate({right: '-=250px'}, 100, function () {
                    $(".sub-modal__close").unbind('click');
                });
        }
    }

    function openSubModal(resp) {
        window.clearTimeout(time);
        block.css('right', '-250px');
        var compiled = _.template(block_tpl.html());

        block.html(compiled({
            data: resp,
            number: function (val) {
                return angular.injector(["ng"]).get("$filter")("number")(val, 2)
            },
            number0: function (val) {
                return angular.injector(["ng"]).get("$filter")("number")(val, 0)
            }
        }));

        $(".sub-modal__close", block).click(function () {
            closeSubModal();
        });

        block.addClass('show');
        block.stop(true, true).animate({right: '+=250px'}, 800,
            function () {
                time = setTimeout(function () {
                    block.stop(true, true).animate({right: '-=250px'}, 100,
                        function () {
                        });
                    block.removeClass('show');
                }, 7000);
            });
    }

    $('.single-product__video, .c-popup-youtube').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        callbacks: {
            markupParse: function (template, values, item) {
                //  Triggers each time when content of popup changes
                // console.log('Parsing:', template, values, item);
                if (item.el.hasClass('vertical-video'))
                    $('iframe', template).addClass('vertical')
            },
        }
    });

    //изменение вида на странице подборки
    var viewCheckbox = $('.view-checkbox > label');
    viewCheckbox.on('click', function () {
        console.log('click');
        $('.subject__list').toggleClass('subject__list_columns');
    });

    //new menu

    (function () {
        const $msg = $('#mgp-is-first');
        if (!isBotBrowser) {
            if ($msg.length) {
                $.magnificPopup.open({
                    closeOnContentClick: true,
                    closeOnBgClick: true,
                    showCloseBtn: true,
                    mainClass: 'mfp-trans',
                    items: [
                        {
                            type: 'inline',
                            preloader: false,
                            modal: true,
                            src: _.template($msg.html())()
                        }
                    ],
                    callbacks: {
                        open: function () {
                            var self = this;
                            setTimeout(function () {
                                self.close()
                            }, 10000)
                        }
                    }
                });
            }
        }
        $('.price-list').magnificPopup({
            type: 'ajax',
            settings: {
                cache: true
            },
            callbacks: {
                ajaxContentAdded: function () {
                    const mfp = this;
                    $('.mfp-link-close').on('click', function () {
                        mfp.close()
                    })
                }
            }
        }).css('visibility', 'visible');
        $('.product__item .product__submenu').each(function (i, el) {
            $('a.product__submenu__item', el).each(function (i, a) {
                const $a = $(a);
                const bottom = $a.position().top + $a.outerHeight(true);
                if (bottom > 100)
                    $a.hide()
            });

            const $more = $('a.product__submenu-more', el);
            const more_count = parseInt($more.data('total')) - $('a.product__submenu__item:visible', el).length;
            if (more_count > 0)
                $more.show();
            $more.text(
                "Еще " + more_count
            );
        })
    }());

    //category image preview change
    (function () {
        $('#product_list .gallery').each(function (i, elem) {
            let t = rxjs.defer(() => {
                return rxjs.from(
                    $.ajax({
                        url: `/catalog/js_api/product/${elem.dataset.productId}/images/`,
                        dataType: 'json',
                        cache: true,
                    }).promise()
                )
            }).pipe(
                rxjs.operators.shareReplay(1),
            );

            rxjs.fromEvent(elem, 'mouseover').pipe(
                rxjs.operators.switchMap((e) => {
                    const defaultImage = {
                        url: e.target.src,
                        annotation: e.target.alt,
                    };
                    return t.pipe(
                        rxjs.operators.switchMap((z) => {
                            const totalImages = z.length;
                            if (totalImages > 1) {
                                return rxjs.interval(1000).pipe(
                                    rxjs.operators.tap((i) => {
                                        const index = (i + 1) % totalImages;
                                        const nextImage = z[index];
                                        e.target.src = nextImage['url'];
                                        e.target.alt = nextImage['annotation'];
                                    }),
                                )
                            } else {
                                return rxjs.of(null)
                            }
                        }),
                        rxjs.operators.takeUntil(rxjs.fromEvent(elem, 'mouseout').pipe(
                            rxjs.operators.tap((e) => {
                                e.target.src = defaultImage['url'];
                                e.target.alt = defaultImage['annotation'];
                            })
                        ))
                    )
                }),
            ).subscribe((e) => {
                console.log(e)
            })
        })
    }());
};
window.mgpSetEntity = function () {
    if (!isBotBrowser) {
        let set_entity_flag = false;
        $.magnificPopup.open({
            closeOnContentClick: false,
            closeOnBgClick: true,
            showCloseBtn: true,
            mainClass: 'mfp-trans',
            items: [
                {
                    type: 'inline',
                    preloader: false,
                    modal: true,
                    src: _.template($('#mgp-set-entity').html())()
                }
            ],
            callbacks: {
                open: function () {
                    var self = this;
                    set_entity_flag = setTimeout(function () {
                        self.close()
                    }, 15000)
                },
                close: function () {
                    if (set_entity_flag)
                        clearTimeout(set_entity_flag);
                    $("form[name=set_entity_default]").submit()
                }
            }
        });
    }
};

$(document).ready(function () {
    $(function () {
        var Accordion = function (el, multiple) {
            this.el = el || {};
            this.multiple = multiple || false;

            //select top-element of active li
            var active = this.el.find('.active.link');
            var topElems = this.el.find('.link-top');
            if (topElems.length) {
                topElems.each(function (index, elem) {
                    $(elem).removeClass('link-top');
                });
            }
            if (active.length) {
                if ($(active).closest('.submenu')) {
                    $(active).closest('.submenu').prev().addClass('link-top');
                } else {
                    $(active).closest('.top-active').find('.link').first().addClass('link-top');
                }
            }

            // Variables privadas
            var links = this.el.find('.link');
            // Evento
            links.on('click', {el: this.el, multiple: this.multiple}, this.dropdown);
        };

        Accordion.prototype.dropdown = function (e) {
            var $el = e.data.el, $this = $(this), $next = $this.next();

            $next.slideToggle();
            $this.closest('li').toggleClass('open');

            if (!e.data.multiple) {
                $el.find('.submenu').not($next).not(e.target.closest('.submenu')).slideUp().parent().removeClass('open');
            }
        };

        var accordion = new Accordion($('#accordion'), false);
        var accordion2 = new Accordion($('#accordion2'), false);

        //menu hamburger
        (function humbMenu() {
            var trigger = $('.humb');
            var menuWrapper = $('.h-menu');
            var close = $('.h-menu__close');

            trigger.on('click', function () {
                menuWrapper.stop(true, true).animate({
                    left: 0
                }, 1000);
            });
            close.on('click', function () {
                menuWrapper.stop(true, true).animate({
                    left: -250
                }, 1000);
                trigger.removeClass('showed');
            })

        })();


        $('.catalog_widget ul li.extendable.collapsing_toggler').click(function () {
            var $li = $(this);
            $li.toggleClass('extended').toggleClass('collapsed');
            return false;
        }).find('a').click(function (event) {
            event.stopPropagation();
        });

        $('.catalog__link').click(function (e) {
            const $li = $(this).closest('li');
            if ($li.hasClass('level_0')) {
                $li.nextUntil('.level_0').find('.catalog__link-second')
                    .slideToggle('show');
            }
            if ($li.hasClass('level_1')) {
                $li.nextUntil('.level_1').find('.catalog__link-third')
                    .slideToggle('show');

            }

            // Разкоментировать для отмены перехода
            //if ($next.length > 0)
            //    e.preventDefault();
        });

        const $active_link = $('.catalog__link.catalog__link-active');

        const $level_0_active = $active_link.closest('li.level_0');

        if ($level_0_active) {
            $level_0_active.nextUntil('.level_0').find('.catalog__link-second').slideToggle('show');
        }


        const $level_1_active = $active_link.closest('li.level_1');
        if ($level_1_active) {
            $level_1_active.prevUntil('.level_0').find('.catalog__link-second').slideToggle('show');
            $level_1_active.nextUntil('.level_0').find('.catalog__link-second').slideToggle('show');
        }

        const $level_2_active = $active_link.closest('li.level_2');
        if ($level_2_active) {
            $level_2_active.prevUntil('.level_0').find('.catalog__link-second').slideToggle('show');
            $level_2_active.nextUntil('.level_0').find('.catalog__link-second').slideToggle('show');
            $level_2_active.prevUntil('.level_1').find('.catalog__link-third').slideToggle('show');
            $level_2_active.nextUntil('.level_1').find('.catalog__link-third').slideToggle('show');
        }

        if ($active_link)
            if (!$active_link.is(":visible"))
                $active_link.slideToggle('show');
    });

    $('#backTop').backTop({
        'position': 400,
        'speed': 500,
        'color': 'violet'
    });

    window.InitApp();
});