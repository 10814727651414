import * as angular from "angular";
import filterCtrl from "./controllers/filterCtrl.coffee";
import cartCtrl from "./controllers/cartCtrl.coffee";

import uiMfp from "./directives/ui-mfp.coffee";
import uiAttrDataTooltip from "./directives/ui-attr.coffee";
import uiPwShow from "./directives/ui-pw-show.coffee";
import uiAutocomplete from "./directives/ui-autocomplete.coffee";
import uiProducts from "./directives/ui-products.coffee";

ppshopApp = angular.module('ppshopApp', [
  require('angular-cookies'),
  require('angular-animate'),
  require('angular-resource'),
  require('angular-sanitize'),
  require('angular-i18n/ru'),
  require('checklist-model'),
  require('angular-local-storage'),
])

ppshopApp.config(['$httpProvider', '$interpolateProvider', '$locationProvider', 'localStorageServiceProvider',
($httpProvider, $interpolateProvider, $locationProvider, localStorageServiceProvider) ->
  localStorageServiceProvider.setPrefix('peropavlina')
  $locationProvider.html5Mode(true)
  $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  $httpProvider.defaults.xsrfCookieName = 'csrftoken';
  $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken'
  $interpolateProvider.startSymbol('{$')
  $interpolateProvider.endSymbol('$}')
])

ppshopApp.run(['$rootScope', '$cookies', (rs, $cookies) ->
  csrfSafeMethod = (method) ->
    (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method))

  $.ajaxSetup({
    crossDomain: false,
    beforeSend: (xhr, settings) ->
      if !csrfSafeMethod(settings.type)
        xhr.setRequestHeader("X-CSRFToken", $cookies.csrftoken)
  })

  rs.JSON = JSON

  rs.Sum = (arr, key) ->
    if not arr
      return 0

    if arr.length == 0
      return 0

    if arr.length == 1
      return arr[0][key]

    return arr.reduce((a, b) ->
      item = {}
      item[key] = a[key] + b[key]
      return item
    )[key]

  rs.Min = Math.min

  rs.$on('$cart_item_count', (e, data) ->
    rs.cart_item_count = data
  )
  rs.$on('$total', (e, data) ->
    rs.total = data
  )
])


ppshopApp.controller('filterCtrl', filterCtrl)
ppshopApp.controller('cartCtrl', cartCtrl)

ppshopApp.directive('uiAttrDataTooltip', uiAttrDataTooltip)
ppshopApp.directive('uiMfp', uiMfp)
ppshopApp.directive('uiPwShow', uiPwShow)
ppshopApp.directive('uiAutocomplete', uiAutocomplete)
ppshopApp.directive('uiProducts', uiProducts)

export default ppshopApp