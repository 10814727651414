export default uiPwShow = () ->
  restrict: 'A',
  replace: true,
  transclude: true,
  template: """<div>
<div ng-transclude></div>
<div class='tooltip pw-show-hide' data-tooltip='Показать пароль'>
  <i class='glyphicon glyphicon-eye-open'></i>
</div>
</div>"""
  link: (s, el, attr) ->
    $icon = el.find('.glyphicon')
    $icon.toggleClass('glyphicon-eye-close')

    $pw_show_hide = el.find('.pw-show-hide')
    $pw_show_hide.on('click', () ->
      $icon.toggleClass('glyphicon-eye-close')

      if ($icon.hasClass('glyphicon-eye-close'))
        el.find('input[type=text]').attr('type', 'password')
        $pw_show_hide.attr('data-tooltip', 'Показать пароль')
      else
        el.find('input[type=password]').attr('type', 'text')
        $pw_show_hide.attr('data-tooltip', 'Скрыть пароль')
    )

    $(document).foundation({
      tooltip: {
        selector: '.tooltip.pw-show-hide',
        additional_inheritable_classes: [],
        tooltip_class: '.tooltip',
        touch_close_text: '',
        disable_for_touch: true,
        tip_template: (selector, content) ->
            '<span data-selector="' + selector + '" class="' + Foundation.libs.tooltip.settings.tooltip_class.substring(1) + '">' + content + '<span class="nub"></span></span>'
      }
    });
    return