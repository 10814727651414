export default filterCtrl = (s, $window, $timeout, localStorageService) ->
  s.show_filters = localStorageService.get('show_filters')
  localStorageService.bind(s, 'show_filters')


  s.min_price = 0
  s.max_price = 0

  s.price__min = 0
  s.price__max = 0
  s.path = $window.location.pathname

  s.params = {}

  term = ""
  params = $.getQueryParameters(location.search)
  if params.hasOwnProperty('term')
    term = decodeURI(params['term'])

  s.count_selected = 0
  query = ""
  $slider = $("#filters-category-prices-slider")

  s.$watch('params', (params) ->
    if Object.keys(params).length
      query = "?" + $.param(params, true)
    else
      query = ""

    selected_filters = Object.keys(params).filter((item) ->
      if ['price__min', 'price__max'].indexOf(item) >= 0
        if item == 'price__min'
          return params[item] != s.min_price
        if item == 'price__max'
          return params[item] != s.max_price
      return params[item].length > 0
    )

    if (selected_filters.indexOf('price__min') >= 0) && (selected_filters.indexOf('price__max') >= 0)
      s.count_selected = selected_filters.length - 1
    else
      s.count_selected = selected_filters.length

  , true)

  s.$watch('min_price', (min_price) ->
    $slider.slider("option", "min", min_price)
    $slider.slider("values", 0, min_price)
  )
  s.$watch('max_price', (max_price) ->
    $slider.slider("option", "max", max_price)
    $slider.slider("values", 1, max_price)
  )

  s.$watch('price__min', (price__min) ->
    if price__min
      $slider.slider("values", 0, price__min)
      s.params.price__min = price__min
    else
      $slider.slider("values", 0, s.min_price)
      delete s.params.price__min
  )
  s.$watch('price__max', (price__max) ->
    if price__max
      $slider.slider("values", 1, price__max)
      s.params.price__max = price__max
    else
      $slider.slider("values", 1, s.max_price)
      delete s.params.price__max
  )

  s.Submit = (close = false) ->
    if (close)
      localStorageService.set('show_filters', false)

    if term
      query += (if query then '&' else "?") + 'term=' + term
    $window.location.href = s.path + query

  s.Reset = () ->
    s.params = {}
    $timeout(() ->
      s.Submit()
    , 100)

  s.clearPrice = () ->
    s.price__min = null
    s.price__max = null

  $slider.slider({
    step: 1,
    range: true,
    stop: (event, ui) ->
      $("input#id_price__min").val($slider.slider("values", 0)).trigger('input')
      $("input#id_price__max").val($slider.slider("values", 1)).trigger('input')
    slide: (event, ui) ->
      $("input#id_price__min").val($slider.slider("values", 0))
      $("input#id_price__max").val($slider.slider("values", 1))
  })

  s.toggleMobileActiveFilter = ($event) ->
    $($event.target).closest('.filters-category').toggleClass('active')
    return null

  return


filterCtrl.$inject = ['$scope', '$window', '$timeout', 'localStorageService']