export default cartCtrl = (s, rs, r, $window) ->
  API_BASE = '/cart/js_api/'
  if /^\/retail\//.test($window.location.pathname)
    API_BASE = '/retail' + API_BASE

  CartRes = r(API_BASE, {}, {
    update: {url: "#{API_BASE}:id/", method: "PUT"}
    checkout: {url: "#{API_BASE}checkout", method: "POST"}
    remove: {url: "#{API_BASE}remove", method: "POST"}
    retrieve: {url: "#{API_BASE}", method: "GET"}
  })
  s.has_without_sale = false
  s.all_check = false

  s.refreshCart = () ->
    CartRes.retrieve((resp_data) ->
      s.cart_data = resp_data
    )

  s.$watch('all_check', (all_check) ->
    for item in s.cart_data.cart_items
      item.checked = all_check
  )

  updateQuantity = (item, index) ->
    CartRes.update({id: item.id}, item, (resp_data) ->
      s.cart_data.cart_items[index].errors = {}

      __mapping = resp_data.cart_items.reduce((result, item) ->
        z = Object()
        z[item.id] = item
        angular.extend(result, z)
        return result
      , {})

      s.cart_data.cart_items.reduce((result, item) ->
        angular.extend(item, __mapping[item.id])
      , {})

      delete resp_data.cart_items
      angular.extend(s.cart_data, resp_data)
    , (resp) ->
      data = resp.data
      s.cart_data.cart_items[index].errors = data
      $.each((data.non_field_errors || []), (i, v) ->
        $.magnificPopup.open({
          items: {
            type: 'inline',
            preloader: false,
            modal: true,
            src: _.template($('#mgp-' + v.code).html())({data: v})
          }, callbacks: {
            open: () ->
              $add_all = $('.mgp-add-all')
              if ($add_all)
                $add_all.click((e) ->
                  e.preventDefault();
                  s.$apply((scope) ->
                    item.quantity = v.actual_rest_count
                    updateQuantity(item, index)
                  )
                  $.magnificPopup.instance.close()
                  return false
                )
              $('.mgp-dismiss').click((e) ->
                e.preventDefault()
                s.$apply((scope) ->
                  item.quantity = v.prev_rest_count
                )
                $.magnificPopup.instance.close()
                return false
              )
          }
        })
        return false
      )
    )

  s.removeItems = ($index) ->
    if $index == 'all'
      $.magnificPopup.open(
        items:
          type: 'inline',
          preloader: false,
          modal: true,
          src: _.template($('#mgp-cart-clear').html())({
            data: $index
          })
        callbacks:
          open: () ->
            $('.mgp-clear').click((e) ->
              e.preventDefault();
              s.$apply((s) ->
                ids = s.cart_data.cart_items.map((item) -> item.id)
                CartRes.remove({ids: ids}, (resp_data) ->
                  $.magnificPopup.instance.close()
                  s.cart_data = {
                    cart_item_count: 0
                    total: 0.0
                    sale_total: 0.0
                    total_weight: 0.0
                    total_volume: 0.0
                    cart_items: []
                  }
                )
              )
              return false
            )
            $('.mgp-dismiss').click((e) ->
              e.preventDefault()
              $.magnificPopup.instance.close()
              return false
            )
      )
    else
      if angular.isArray($index)
        ids = $index.map((i) -> s.cart_data.cart_items[i].id)
      else
        ids = [s.cart_data.cart_items[$index].id]

      CartRes.remove({ids: ids}, (resp_data) ->
        s.all_check = false
        angular.extend(s.cart_data, resp_data)
#        for item, index in s.cart_data.cart_items
#          if item.id == id
#            s.cart_data.cart_items.splice(index, 1)
#            break
      )

  s.removeCheckedItems = () ->
    indexes = []
    for item, index in s.cart_data.cart_items
      if item.checked
        indexes.push(index)

    if indexes.length
      if indexes.length == s.cart_data.cart_items.length
        s.all_check = false
        s.removeItems('all')
      else
        s.removeItems(indexes)

  edostCallback = () ->
    if $("#id_weight").val() == ''
      $("#id_weight").val(
        s.cart_data.cart_items.map((item) -> parseFloat(item.weight)).reduce((part_sum, a) -> part_sum + a)
      )
    $edost_btn = $("#cart-edost-btn")
    $edost_form = $("#cart-edost-form")
    $edost_form.submit(() ->
      $edost_btn.trigger('click')
    )
    $edost_btn.click((e) ->
      $edost_btn.attr('disabled', 'disabled')
      $edost_form.ajaxSubmit({
        target: "#cart-edost",
        async: false,
        url: "#{API_BASE}edost",
        success: (responseText, statusText, xhr, $form) ->
          $edost_btn.removeAttr('disabled')
          edostCallback()
        error: () ->
          $edost_btn.removeAttr('disabled')
      })
    )

  s.showEDost = () ->
    $.magnificPopup.open(
      items:
        type: 'ajax',
        preloader: false,
        modal: true,
        src: "#{API_BASE}edost"
      callbacks: {
        parseAjax: (mfpResponse) ->
          mfpResponse.data = "<div id='cart-edost'>#{$(mfpResponse.data).prop('outerHTML')}</div>"
        ajaxContentAdded: edostCallback
      }
    )

  s.DisableDblclick = (e) ->
    e.stopPropagation()
    e.preventDefault()
    return false

  TabChain = () ->
    tabs = $('.mfp-tab')
    tabs.each((index) ->
      $tab = $(this)
      $('.next', $tab).click(() ->
        $tab.removeClass('active')
        if tabs[index + 1]
          $(tabs[index + 1]).addClass('active')
      )
      $('.back', $tab).click(() ->
        $tab.removeClass('active')
        if tabs[index - 1]
          $(tabs[index - 1]).addClass('active')
      )
    )

  CheckoutCallback = () ->
    TabChain()

    $btn = $('.cart-checkout-btn')
    $payment_btn = $('.cart-payment-btn')
    $cart_checkout_form = $('#cart-checkout-form')
    $btn2 = $('.cart-simple-reg-checkout-btn')
    $simple_reg_form = $('#simple-reg-form')
    $payment_form = $('form[name=payment]')
    $select_pickpoint = $('.select_pickpoint')
    $radio_need_cell = $("input[name=need_cell]")
    $address_input = $('input#id_address')
    $pickpoint_data_input = $("input[name=pickpoint_data]")
    $manager_info = $(".manager-info")

    if $payment_form.length
      $payment_form.submit()

    RequiredItems = () ->
      [$cart_checkout_form, $simple_reg_form].map(($form) ->
        $inputs = $('input.required:visible', $form)

        arr = new Array($inputs.length)
        $next = $('.next', $form)

        if not $inputs.length
          $next.prop('disabled', 0)
          $next.removeClass('disabled')

        $inputs.each((index) ->
          $input = $($inputs[index])
          arr[index] = $input.val() or ''
        )

        $inputs.each((index) ->
          $($inputs[index]).on('change paste keyup', (e) ->
            arr[index] = $(this).val()

            if ("" in arr) or (undefined in arr)
              $next.prop('disabled', 1)
              $next.addClass('disabled')
            else
              $next.prop('disabled', 0)
              $next.removeClass('disabled')
          )
        )

        if ($address_input.length)
          $address_input.attr('readonly', ($("input[name=shipping]:checked").val() or '').indexOf('pickpoint') >= 0)


        if ("" in arr) or (undefined in arr)
          $next.prop('disabled', true)
          $next.addClass('disabled')
        else
          $next.prop('disabled', 0)
          $next.removeClass('disabled')
      )
    RequiredItems()

    $cart_checkout_form.submit(() ->
      $btn2.trigger('click')
    )

    $btn.click((e) ->
      $btn.attr('disabled', 'disabled')
      e.preventDefault()
      $cart_checkout_form.ajaxSubmit({
        target: '#cart-checkout'
        url: "#{API_BASE}checkout"
        success: (responseText, statusText, xhr, $form) ->
          $btn.removeAttr('disabled')
          CheckoutCallback()
        error: () ->
          $btn.removeAttr('disabled')
      })
      return false
    )
    $btn.dblclick((e) ->
      e.stopPropagation()
      e.preventDefault()
      return false
    )

    $payment_btn.on('click', (e) ->
      e.preventDefault()
      $payment_btn.attr('disabled', 'disabled')

      $cart_checkout_form.ajaxSubmit({
        target: '#cart-checkout'
        url: "#{API_BASE}checkout"
        async: false,
        data: {
          do_payment: 'true'
        }
        success: (responseText, statusText, xhr, $form) ->
          $payment_btn.removeAttr('disabled')
          CheckoutCallback()
        error: () ->
          $payment_btn.removeAttr('disabled')
      })

      $simple_reg_form.ajaxSubmit({
        target: '#cart-checkout'
        url: "#{API_BASE}anonim_checkout"
        async: false,
        data: {
          do_payment: 'true'
        }
        success: (responseText, statusText, xhr, $form) ->
          $payment_btn.removeAttr('disabled')
          CheckoutCallback()
        error: () ->
          $payment_btn.removeAttr('disabled')
      })

      return false
    )

    $payment_btn.dblclick((e) ->
      e.stopPropagation()
      e.preventDefault()
      return false
    )


    $simple_reg_form.submit(() ->
      $btn2.trigger('click')
    )

    $btn2.click((e) ->
      $btn2.attr('disabled', 'disabled')
      e.preventDefault()
      $simple_reg_form.ajaxSubmit({
        target: '#cart-checkout'
        url: "#{API_BASE}anonim_checkout"
        success: (responseText, statusText, xhr, $form) ->
          $btn2.removeAttr('disabled')
          CheckoutCallback()
        error: () ->
          $btn2.removeAttr('disabled')
      })
      return false
    )
    $btn2.dblclick((e) ->
      e.stopPropagation()
      e.preventDefault()
      return false
    )

    pickPointCallback = (result, $address_input) ->
      $address_input.val(result.name + ',' + result.address)
      $pickpoint_data_input.val(JSON.stringify(result))
      RequiredItems()
      return

    RadioTrigger = (e) ->
      $this = $(this)

      if $this.prop('name') == 'shipping'
        $msg1 = $("#about_contact")
        $msg2 = $("#shipping_address")
        $self_payment = $("input[name=payment][value=cash]")
        $self_payment.closest('li').show()
        $select_pickpoint.hide()

        $address_input.attr('placeholder', 'Введите адрес доставки')

        if $this.prop('checked')
          if $this.val() == 'self'
            $msg2.hide()
            $manager_info.show()
            if $msg1.length == 0
              $this.closest('li').append("""
<a id="about_contact" href="/about/schema/" target="popup" onclick="window.open('/about/schema/','popup','width=600,height=600,scrollbars=no,resizable=no'); return false;">Как нас найти</a>
""")
            else
              $msg1.show()

          else if $this.val() in ['tk', 'town']
            $msg1.hide()
            $msg2.show()
            $manager_info.hide()

          else if $this.val().indexOf('pickpoint') >= 0
            $self_payment.closest('li').hide()
            $("input[name=payment][value=yandex]").prop('checked', true).change()
            $('#select_' + $this.val()).show()
            $address_input.attr('placeholder', 'Выбрать пункт выдачи').val('')

            #            $select_pickpoint.show()
            #            $select_pickpoint.show()

            $msg1.hide()
            $msg2.show()
            $manager_info.hide()

          else if $this.val() == 'post'
            $self_payment.closest('li').hide()
            $("input[name=payment][value=yandex]").prop('checked', true).change()
            $msg1.hide()
            $msg2.show()
            $manager_info.hide()
          else
            $msg1.hide()
            $msg2.hide()
            $manager_info.hide()

      if $this.prop('name') == 'payment'
        if $this.prop('checked')
          if ['yandex', 'card', 'net2pay', 'yookassa', 'sberpay'].indexOf($this.val()) >= 0
            $payment_btn.removeAttr('disabled')
          else
            $payment_btn.attr('disabled', 'disabled')

      RequiredItems()
      return e

    $('input[name=shipping]').on('change click', RadioTrigger);
    $('input[name=shipping]:checked').each(RadioTrigger);
    $('input[name=payment]').on('change click', RadioTrigger);
    $('input[name=payment]:checked').each(RadioTrigger);

    $('.magnific-dismiss').click((e) ->
      e.preventDefault()
      $.magnificPopup.instance.close()
      return false
    )

    $select_pickpoint.click(() ->
      if window.PickPoint
        PickPoint.open((result) ->
          pickPointCallback(result, $address_input)
        )
      return false
    )

    $radio_need_cell.click(() ->
      $btn.removeAttr('disabled')
    )

  s.checkoutAnonimOrder = () ->
    $.magnificPopup.open(
      items:
        type: 'ajax',
        preloader: false,
        modal: true,
        src: "#{API_BASE}anonim_checkout"
      callbacks: {
        parseAjax: (mfpResponse) ->
          mfpResponse.data = "<div id='cart-checkout'>#{$(mfpResponse.data).prop('outerHTML')}</div>"
        ajaxContentAdded: CheckoutCallback
      }
    )

  s.checkoutOrder = () ->
    $.magnificPopup.open(
      items:
        type: 'ajax',
        preloader: false,
        modal: true,
        src: "#{API_BASE}checkout"
      callbacks: {
        parseAjax: (mfpResponse) ->
          mfpResponse.data = "<div id='cart-checkout'>#{$(mfpResponse.data).prop('outerHTML')}</div>"
        ajaxContentAdded: CheckoutCallback
      }
    )

  s.$watch('cart_data', (data, old_data) ->
#    console.log data
    s.has_without_sale = false
    for item, index in data.cart_items
      if item.product.without_sale and (item.product.max_sale == 0)
        s.has_without_sale = true
      if old_data
        if old_data.cart_items[index]
          if item.quantity != old_data.cart_items[index].quantity
            updateQuantity(item, index)

    rs.$broadcast('$cart_item_count', data.cart_item_count)
    rs.$broadcast('$total', data.total)
  , true)

cartCtrl.$inject = ['$scope', '$rootScope', '$resource', '$window']