import "./styles/app/app.scss";

import "./vendor/bxslider/jquery.bxslider.css";

import "./styles/vendor/angular-csp.css";
import "./styles/vendor/backTop.css";
import "./styles/vendor/magnific-popup.css";

import "./styles/img/favicon.png";
import "./styles/img/favicon.ico";
import "./styles/img/favicon.svg";
import "./styles/img/new-hit-5.png";
import "./styles/img/icon_excel.png";
import "./styles/img/pick-point-logo.png";
import "./styles/img/pick-point-logo-big.png";
import "./styles/img/no_img.gif";
import "./styles/img/showfilter.png";
import "./styles/img/showfilterhover.png";
import "./styles/img/blank.gif";
import "./styles/img/youtube_70p.png";
import "./styles/img/youtube_300.png";
import "./styles/img/vk_70.png";
import "./styles/img/vk_300.png";

function importAll(r) {
    return r.keys().map(r);
}

const nophoto = importAll(require.context('./styles/img/nophoto', false, /\.(png|jpe?g|svg|gif)$/));
const delivery_img = importAll(require.context('./styles/img/delivery_img', false, /\.(png|jpe?g|svg|gif)$/));
const icons = importAll(require.context('./styles/img/icons', false, /\.(png|jpe?g|svg|gif)$/));
const logo = importAll(require.context('./styles/img/logo', false, /\.(png|jpe?g|svg|gif)$/));

import ppshopApp from "./app/root.coffee";

require("jquery-ui/ui/core");
require("imports-loader?define=>false!jquery-ui/ui/version");
require("imports-loader?define=>false!jquery-ui/ui/widget");
require("imports-loader?define=>false!jquery-ui/ui/keycode");
require("imports-loader?define=>false!jquery-ui/ui/widgets/mouse");
require("imports-loader?define=>false!jquery-ui/ui/widgets/slider");
require("jquery-ui-touch-punch");

require("./vendor/jquery.form");
require("./vendor/jquery.backTop.min");
require("imports-loader?define=>false&exports=>false!./vendor/jquery.magnific-popup");
require("./vendor/jquery.maskedinput");
require("./vendor/jquery.dotdotdot.min");
require("./vendor/foundation/foundation");
require("./vendor/foundation/foundation.tooltip");

require("./_scripts");