export default uiAutocomplete = ['$http', ($http) ->
  restrict: 'AE',
  replace: true,
  transclude: true,
  templateUrl: 'search-autocomplete'
  scope: {
    uiAutocomplete: '@'
  }
  link: (s, el) ->
    input = el.find('input')
    s.results = []
    if input
      rxjs.fromEvent(input, 'focusin').pipe(
        rxjs.operators.switchMap(() ->
          return rxjs.fromEvent(input, 'keyup').pipe(
            rxjs.operators.debounceTime(500),
            rxjs.operators.map((e) ->
              return e.currentTarget.value.trim()
            ),
            rxjs.operators.filter((term) ->
              return term.length >= 3
            ),
            rxjs.operators.distinctUntilChanged(),
            rxjs.operators.switchMap((term) ->
              return rxjs.from($http.get(s.uiAutocomplete + '?term=' + term)).pipe(
                rxjs.operators.catchError((err) ->
                  console.error(err)
                  return rxjs.operators.of(null)
                )
              )
            ),
            rxjs.operators.tap((resp) ->
              s.results = if resp.status == 200 then resp.data else []
            )
            rxjs.operators.takeUntil(rxjs.fromEvent(input, 'focusout').pipe(
              rxjs.operators.tap(() ->
                s.results = []
              )
            ))
          )
        )
      ).subscribe()
    return
]