export default uiProducts = ['$http', '$sce', '$timeout', ($http, $sce, $timeout) ->
  limit = 12
  return {
    restrict: 'A',
    replace: true,
    transclude: true,
    template: """
  <ul>
    <ng-transclude></ng-transclude>
    <li ng-repeat='item in contents' ng-bind-html='item.content' class="f-product__item"></li>
    <div ng-if='show_loader' style="text-align: center">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </ul>
  """
    link: (s, el, attr) ->
#      term = "";
      params = $.getQueryParameters(decodeURIComponent(location.search))

#      if (params.hasOwnProperty('term'))
#        term = decodeURI(params['term'])

      s.contents = []
      $loader = new rxjs.BehaviorSubject(false)
      $loader.subscribe((value) ->
        s.show_loader = value
      )
      getMaxScroll = (node) ->
        scrollHeight = Math.max(
          node.body.scrollHeight,
          node.body.offsetHeight,
          node.body.clientHeight,
          node.documentElement.scrollHeight,
          node.documentElement.offsetHeight,
          node.documentElement.clientHeight,
        )
        clientHeight = node.documentElement.clientHeight
        return scrollHeight - clientHeight

      rxjs.fromEvent(window, "scroll").pipe(
        rxjs.operators.debounceTime(300),
        rxjs.operators.map((event) ->
          currentScroll = window.pageYOffset
          maxScroll = getMaxScroll(document)
          return {
            'currentScroll': currentScroll,
            'maxScroll': maxScroll,
          }
        ),
        rxjs.operators.filter((v) ->
          z = $('.f-product__item:last').offset()
          return (v['currentScroll'] + document.documentElement.clientHeight) >= z['top']
        ),
        rxjs.operators.exhaustMap((v) ->
          $loader.next(true)
          return rxjs.from(
            $http.get("?#{$.param(params, true)}&limit=#{limit}&offset=" + $('.f-product__item').length)
          ).pipe(
            rxjs.operators.catchError((err) ->
              console.error(err)
              $loader.next(false)
              return rxjs.operators.of(null)
            )
          )
        ),
        rxjs.operators.switchMap((resp) ->
          s.contents.push.apply(
            s.contents, resp.data['content_list'].map((content) ->
              return {
                content: $sce.trustAsHtml(
                  content
                )
              }
            )
          )
          return rxjs.from(
            $timeout(() ->
              window.InitApp()
            )
          ).pipe(
            rxjs.operators.tap(() ->
              window.scrollTo(window.scrollX, window.pageYOffset + 1);
              $loader.next(false)
            ),
            rxjs.operators.mapTo(resp)
          )
        )
        rxjs.operators.takeWhile((resp) ->
          return $('.f-product__item').length < resp.data.total
        ),
      ).subscribe()
      return
  }
]